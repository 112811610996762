<template>
  <div class="">
    <form @submit.prevent="deleteRoomUser" v-if="removeRoomId">
      <select v-model="removeUserId">
        <option default value="">Select User</option>
        <option v-for="user in removeUsers" :key="user._id" :value="user._id">
          {{ user.username }}
        </option>
      </select>
      <button type="submit" :disabled="disableForm || !removeUserId">Remove User</button>
      <button class="button-cancel" @click="removeRoomId = null">Cancel</button>
    </form>
    <!-- :load-first-room="false" is important for adding rooms: addRoom -->
    <vue-advanced-chat
      :height="chatHeight"
      :styles="JSON.stringify(styles)"
      :show-add-room="!mini"
      :show-search="!mini"
      :rooms-list-opened="!mini"
      :single-room="mini"
      show-audio="true"
      show-files="true"
      load-first-room="true"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :rooms="JSON.stringify(rooms)"
      :rooms-loaded="roomsLoaded"
      :loading-rooms="loadingRooms"
      :messages="JSON.stringify(messages)"
      :room-actions="!mini && JSON.stringify(roomActions)"
      :menu-actions="!mini && JSON.stringify(menuActions)"
      :message-actions="JSON.stringify(messageActions)"
      :messages-loaded="messagesLoaded"
      @fetch-more-rooms="fetchMoreRooms($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
      @send-message="sendMessage($event.detail[0])"
      data-edit-message="editMessage($event.detail[0])"
      data-delete-message="deleteMessage($event.detail[0])"
      @open-file="openFile($event.detail[0])"
      @add-room="addRoom($event.detail[0])"
      @room-action-handler="!mini && menuActionHandler($event.detail[0])"
      @menu-action-handler="!mini && menuActionHandler($event.detail[0])"
      @send-message-reaction="sendMessageReaction($event.detail[0])"
    />
  </div>
</template>

<script>
// import "vue-advanced-chat/dist/vue-advanced-chat.css";

import { parseTimestamp, isSameDay } from "@/utils/dates";
import {
  buttonBgColor,
  // commonBgColor,
  courseElementBgColor,
  buttonBgColorHover,
  // buttonBgColorActive,
  // buttonRedBgColor,
  // buttonRedBgColorHover,
  // buttonRedBgColorActive,
  // buttonGreenBgColor,
  // buttonGreenBgColorHover,
  // buttonGreenBgColorActive,
} from "@/utils/variables.js";

// import { EventBus } from '@/event-bus';

import { register } from "vue-advanced-chat";
register();

import ChatService from "@/services/ChatService.js";
import S3DataService from "@/services/S3DataService.js";

//import mixins
import windowSize from "@/mixins/windowSize.js";

let API_ROUTE = process.env.VUE_APP_API_URL + "img/";

export default {
  metaInfo: {
    title: "Chat",
  },
  mixins: [windowSize],
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstFetch: true,

      roomsPerPage: 15,
      rooms: [],
      roomId: "",
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      roomMessage: "",
      startMessages: null,
      endMessages: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: "",
      disableForm: false,
      addNewRoom: null,
      addRoomUserId: "",
      inviteRoomId: null,
      invitedUserId: "",
      removeRoomId: null,
      removeUserId: "",
      removeUsers: [],
      // roomActions: [{ name: "deleteRoom", title: "Delete Room" }],
      roomActions: [],
      // menuActions: [{ name: "deleteRoom", title: "Delete Room" }],
      menuActions: [],
      messageActions: [
        {
          name: "replyMessage",
          title: "Reply",
        },
        // {
        //   name: "editMessage",
        //   title: "Edit Message",
        //   onlyMe: true,
        // },
        // {
        //   name: "deleteMessage",
        //   title: "Delete Message",
        //   onlyMe: true,
        // },
        {
          name: "selectMessages",
          title: "Select",
        },
      ],
      styles: {
        container: {
          borderRadius: "4px",
        },
        message: {
          backgroundImage: "#fff",
          backgroundMe: courseElementBgColor,
          colorNewMessages: buttonBgColorHover,
        },
        icons: {
          add: buttonBgColor,
          dropdownRoom: "#000",
          send: buttonBgColor,
          emoji: buttonBgColor,
        },
        sidemenu: {
          backgroundActive: courseElementBgColor,
        },
        room: {
          colorMessage: "#000",
          colorTimestamp: "#000",
        },
      },
      // ,dbRequestCount: 0
      currentUserId: this.$store.state.user_module.user._id,
    };
  },
  sockets: {
    connect() {},

    newMessage(roomId, message) {
      let room = this.rooms.find((r) => r._id === roomId);
      if (room && room.roomId === this.selectedRoom) {
        const formattedMessage = this.formatMessage(room, message);
        const messageIndex = this.messages.findIndex((m) => m._id === message._id);

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage]);
        } else {
          this.$set(this.messages, messageIndex, formattedMessage);
        }
      }
    },

    deleteMessage(roomId, messageId) {
      let room = this.rooms.find((r) => r._id === roomId);
      if (room && room.roomId === this.selectedRoom) {
        this.messages = this.messages.filter((m) => m._id !== messageId);
      }
    },

    addRoom() {
      this.fetchMoreRooms();
    },

    deleteRoom(roomId) {
      this.rooms = this.rooms.filter((r) => r._id !== roomId);
    },
  },
  created() {
    // EventBus.$once('create-room', (userId) => {
    //   this.changeAddRoomUserId(userId);
    //   EventBus.$off('create-room');
    // });
  },
  mounted() {
    if (!this.$route.params.user_id && !this.mini) {
      this.fetchRooms();
    }
  },
  destroyed() {
    this.resetRooms();
  },
  methods: {
    // async addCss() {
    //   if (process.env.NODE_ENV === "development") {
    //     const styles = await import("./../../src/styles/index.scss");
    //     const style = document.createElement("style");
    //     style.innerHTML = styles.default;
    //     this.$refs.chatWindow.shadowRoot.appendChild(style);
    //   }
    // },
    // createdRoomCheck() {
    //   let create_room_id = this.$store.state.user_module ? this.$store.state.user_module.create_room_id : this.$store.state.user_module.create_room_id;
    //   let user_type = this.$store.state.user_module ? 'student' : 'teacher';
    //   if (create_room_id) {
    //     this.addRoomUserId = create_room_id;
    //     this.createRoom();
    //     this.$store.dispatch('user_module' + '/removeCreateRoomId');
    //   }
    // },
    changeAddRoomUserId(userId) {
      this.addRoomUserId = userId;
      this.createRoom();
    },
    resetRooms() {
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = false;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      this.resetMessages();
    },

    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.startMessages = null;
      this.endMessages = null;
      this.listeners.forEach((listener) => listener());
      this.listeners = [];
    },

    fetchRooms(select_room = "") {
      this.resetRooms();
      this.fetchMoreRooms(select_room);
    },

    async fetchMoreRooms(select_room = "") {
      try {
        let startAfter = "";
        if (this.endRooms && !this.startRooms) {
          this.$Progress.finish();
          this.roomsLoaded = true;
          return;
        }

        if (this.startRooms) startAfter = this.startRooms;

        const rooms = await ChatService.getRooms(startAfter)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log(err);
          });

        if (rooms.length) {
          this.roomsLoaded = rooms.length < this.roomsPerPage;
          if (this.roomsLoaded) {
            this.$Progress.finish();
          }

          if (this.startRooms) this.endRooms = this.startRooms;
          this.startRooms = rooms[0].lastUpdated;

          const roomUserIds = [];
          rooms.forEach((room) => {
            room.users.forEach((userId) => {
              const foundUser = this.allUsers.find((user) => user._id === userId);
              if (!foundUser && roomUserIds.indexOf(userId) === -1) {
                roomUserIds.push(userId);
              }
            });
          });

          // this.incrementDbCounter('Fetch Room Users', roomUserIds.length);
          const rawUsers = [];
          roomUserIds.forEach((userId) => {
            const promise = ChatService.getUser(userId).then((res) => {
              return res.data;
            });

            rawUsers.push(promise);
          });

          this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];

          const roomList = {};
          rooms.forEach((room) => {
            roomList[room._id] = { ...room, users: [] };

            room.users.forEach((userId) => {
              const foundUser = this.allUsers.find((user) => user._id === userId);
              if (foundUser) roomList[room._id].users.push(foundUser);
            });
          });

          const formattedRooms = [];

          Object.keys(roomList).forEach((key) => {
            const room = roomList[key];

            const roomContacts = room.users.filter(
              (user) => user._id !== this.currentUserId
            );
            room.lastUpdated = new Date(room.lastUpdated);
            room.roomName =
              roomContacts.map((user) => user.user_type_info.first_name).join(", ") ||
              "Myself";

            const roomAvatar =
              roomContacts.length === 1 && roomContacts[0].avatar
                ? API_ROUTE + roomContacts[0].avatar
                : require("@/assets/img/no_photo_squared_white_bg.png");
            formattedRooms.push({
              ...room,
              roomId: key,
              avatar: roomAvatar,
              index: room.lastUpdated.getTime(),
              lastMessage: {
                content: room.lastMessageContent,
                timestamp: this.formatTimestamp(
                  new Date(room.lastUpdated.getTime()),
                  room.lastUpdated
                ),
              },
            });
          });
          this.$Progress.finish();
          this.rooms = this.rooms.concat(formattedRooms);

          if (select_room) {
            this.roomId = select_room;
          }
          this.loadingRooms = false;
        } else {
          this.loadingRooms = false;
          this.$Progress.finish();
        }
      } catch (error) {
        console.log(error);
      }
    },

    formatTimestamp(date, timestamp) {
      const timestampFormat = isSameDay(date, new Date()) ? "HH:mm" : "DD/MM/YY";
      const result = parseTimestamp(timestamp, timestampFormat);
      return timestampFormat === "HH:mm" ? `Today, ${result}` : result;
    },

    addRoom() {
      this.resetForms();
      this.$emit("show-users");
      this.addNewRoom = true;
    },

    inviteUser(roomId) {
      this.resetForms();
      this.inviteRoomId = roomId;
    },

    async createRoom() {
      try {
        this.disableForm = true;
        let res = await ChatService.addRoom({
          users: [this.addRoomUserId, this.currentUserId],
          lastUpdated: new Date(),
          socket_id: this.$socket.client.id,
        });

        this.addNewRoom = false;
        this.addRoomUserId = "";
        this.fetchRooms(res.data.room_id);
      } catch (err) {
        if (
          err.response.data.message == "Room already exists." ||
          err.response.data.message == "Can't create room with duplicate users."
        ) {
          this.addNewRoom = false;
          this.roomId = err.response.data.room_id;
          this.fetchRooms();
        }
      }
    },

    async addRoomUser() {
      this.disableForm = true;

      await ChatService.updateRoom(this.inviteRoomId, "users", this.invitedUserId, {
        action: "add",
      });

      this.inviteRoomId = null;
      this.invitedUserId = "";
      this.fetchRooms();
    },

    async deleteRoom(roomId) {
      const room = this.rooms.find((r) => r.roomId === roomId);
      if (room) {
        if (this.roomId == roomId) {
          this.roomId = "";
        }
        await ChatService.deleteRoom(roomId);
        this.fetchRooms();
      }
    },

    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        case "deleteRoom":
          return this.deleteRoom(roomId);
      }
    },

    fetchMessages({ room, options }) {
      if (!room) {
        return;
      }

      if (options.reset) {
        this.resetMessages();
        this.roomId = room.roomId;
      }

      this.selectedRoom = room.roomId;

      ChatService.getMessages(room.roomId).then((res) => {
        let messages = res.data.slice().sort(function (a, b) {
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
        // this.incrementDbCounter('Fetch Room Messages', messages.size)
        if (this.selectedRoom !== room.roomId) return;

        if (messages.length == 0) this.messagesLoaded = true;

        if (options.reset) this.messages = [];

        messages.forEach((message) => {
          const formattedMessage = this.formatMessage(room, message);
          this.messages.unshift(formattedMessage);
        });

        this.messagesLoaded = true;
        if (this.firstFetch) {
          this.$Progress.finish();
          this.firstFetch = false;
        }
      });
    },

    async sendMessage({ content, roomId, files, replyMessage }) {
      try {
        let room = this.rooms.find((r) => r._id === roomId);
        const message = {
          sender_id: this.currentUserId,
          content,
          timestamp: new Date(),
        };

        if (files) {
          message.files = this.formattedFiles(files);
        }

        if (replyMessage) {
          message.replyMessage = {
            _id: replyMessage._id,
            content: replyMessage.content,
            sender_id: replyMessage.senderId,
          };

          if (replyMessage.files) {
            message.replyMessage.files = replyMessage.files;
          }
        }

        let { addedMessage, forms } = await ChatService.addMessage(roomId, message).then(
          (res) => {
            return {
              addedMessage: res.data.message,
              forms: res.data.forms,
            };
          }
        );
        message._id = addedMessage._id;

        let newFormatedMessage = this.formatMessage(room, message);
        this.messages = this.messages.concat([newFormatedMessage]);

        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const form = forms[i];
            await this.uploadFile({
              file: file,
              form: form,
              messageId: addedMessage._id,
              fileIndex: i,
            });
          }
        }
        await ChatService.sendMessage(roomId, addedMessage._id);

        // const { id } = await ChatService.addMessage(roomId, message).then(
        //   (res) => res.data
        // );

        // roomsRef.doc(roomId).update({ lastUpdated: new Date() })
      } catch (error) {
        console.log(error.message);
      }
    },

    async editMessage({ messageId, newContent, roomId, files }) {
      let room = this.rooms.find((r) => r._id === roomId);

      const newMessage = { edited: new Date() };
      newMessage.content = newContent;

      if (files) {
        newMessage.files = this.formattedFiles(files);
      }
      let { updatedMessage, forms } = await ChatService.updateMessage(
        roomId,
        messageId,
        "message",
        newMessage,
        {}
      ).then((res) => {
        return {
          updatedMessage: res.data.message,
          forms: res.data.forms,
        };
      });

      if (files) {
        let messageIndex = this.messages.findIndex((m) => m._id == messageId);
        let formatedMessage = this.formatMessage(room, updatedMessage);
        this.$set(this.messages, messageIndex, formatedMessage);
        for (let i = 0; i < files.length; i++) {
          if (forms[i]) {
            let file = files[i];
            let form = forms[i];
            await this.uploadFile({
              file: file,
              form: form,
              messageId: updatedMessage._id,
              fileIndex: i,
            });
          }
        }
      }

      await ChatService.sendMessage(roomId, updatedMessage._id);
    },

    async deleteMessage({ message, roomId }) {
      await ChatService.deleteMessage(roomId, message._id);
    },

    async sendMessageReaction({ reaction, remove = false, messageId, roomId }) {
      console.log(reaction);
      await ChatService.updateMessage(roomId, messageId, "reactions", reaction.unicode, {
        remove: remove,
      });
    },

    uploadFileProgress(progressEvent, messageId, fileIndex) {
      let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (messageId) {
        let messageIndex = this.messages.findIndex((m) => m._id == messageId);
        let message = this.messages[messageIndex];
        message.files[fileIndex].progress = progress;
        this.$set(this.messages, messageIndex, message);
      }
    },

    uploadFile({ file, form, messageId, fileIndex }) {
      return S3DataService.uploadFileToS3(
        form,
        file.blob,
        this.uploadFileProgress,
        messageId,
        fileIndex
      );
    },

    // async uploadFile({ file, messageId, roomId, lastUpdated }) {
    //   let type = file.extension || file.type;
    //   if (type === "svg" || type === "pdf") {
    //     type = file.type;
    //   }

    //   const fd = new FormData();
    //   fd.append("lastUpdated", lastUpdated);
    //   fd.append("type", file.type);
    //   fd.append("extension", file.extension);
    //   fd.append("file", file.blob, `${file.name}.${type}`);
    //   await ChatService.uploadFile(fd, roomId, this.currentUserId, messageId).then(
    //     (res) => res.data
    //   );
    // },

    openFile({ file }) {
      window.open(file.file.url, "_blank");
    },

    formattedFiles(files) {
      const formattedFiles = [];

      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        };

        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }

        formattedFiles.push(messageFile);
      });

      return formattedFiles;
    },

    formatMessage(room, message) {
      const senderUser = room.users.find((user) => message.sender_id === user._id);

      const sender_id = message.sender_id;
      const timestamp = new Date(message.timestamp);

      const formattedMessage = {
        ...message,
        ...{
          _id: message._id,
          senderId: sender_id,
          seconds: timestamp.getTime() / 1000,
          timestamp: parseTimestamp(timestamp, "HH:mm"),
          date: parseTimestamp(timestamp, "DD MMMM YYYY"),
          username: senderUser ? senderUser.user_type_info.first_name : null,
          avatar: senderUser.avatar
            ? API_ROUTE + senderUser.avatar
            : require("@/assets/img/no_photo_squared_white_bg.png"),
          distributed: true,
        },
      };

      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.sender_id,
          },
        };
      }

      return formattedMessage;
    },

    formatLastMessage(message) {
      if (!message.timestamp) return;
      message.timestamp = new Date(message.timestamp);
      let content = message.content;
      if (message.file)
        content = `${message.file.name}.${message.file.extension || message.file.type}`;

      return {
        ...message,
        ...{
          content,
          timestamp: this.formatTimestamp(
            new Date(message.timestamp.getTime()),
            message.timestamp
          ),
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
            message.sender_id !== this.currentUserId &&
            (!message.seen || !message.seen[this.currentUserId]),
        },
      };
    },

    resetForms() {
      this.disableForm = false;
      this.addNewRoom = null;
      this.addRoomUserId = "";
      this.inviteRoomId = null;
      this.invitedUserId = "";
      this.removeRoomId = null;
      this.removeUserId = "";
    },
  },
  computed: {
    chatHeight() {
      if (this.windowWidth < 1200) {
        return "calc(100vh - 63px)";
      } else if (this.windowWidth >= 1200 && this.mini) {
        return "min(600px, calc(100vh - 90px))";
      } else {
        return "calc(100vh - 90px)";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.window-container {
  width: 100%;
}

.window-mobile {
  form {
    padding: 0 10px 10px;
  }
}

form {
  padding-bottom: 20px;
}

input {
  padding: 5px;
  width: 140px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  outline: none;
  font-size: 14px;
  vertical-align: middle;

  &::placeholder {
    color: #9ca6af;
  }
}

button {
  background: #1976d2;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: middle;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
  }
}

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>
