<template lang="html">
  <div class="image-container">
    <ImageItemNew source="https://via.placeholder.com/160x90" :aspectRatio="1" />
  </div>
</template>
<script>
import ImageItemNew from "./ImageItemZoom.vue";

export default {
  components: {
    ImageItemNew,
  },
};
</script>
<style lang="scss" scoped>
.image-container {
  width: 200px;
  height: 200px;
}
</style>
