<template>
  <div v-lazyload :class="imageClass('image__wrapper')">
    <div class="image__spinner">
      <div :class="imageClass('loader')"></div>
    </div>
    <div
      class="zoom-button"
      :class="{ 'zoom-button--vissible': containerHovered || hovered }"
      @click="showDialog"
    >
      <font-awesome-icon icon="search-plus" />
    </div>
    <img
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      class="image__item"
      :data-url="source"
      alt="an image"
      ref="thumb"
    />
    <transition name="dialog" @enter="enter" @leave="leave">
      <div class="image-dialog-background" v-show="appearedDialog" ref="dialog">
        <div class="image-dialog-container" @click.self="hideDialog">
          <img
            class="image-dialog-animate image-dialog"
            ref="animate"
            :class="{ loading: !loaded }"
            :src="source"
          />
          <div class="image-dialog-block">
            <div class="image-dialog-close" @click="hideDialog" aria-label="Close">
              <font-awesome-icon icon="times" />
            </div>
            <img
              class="image-dialog-full image-dialog"
              :src="appearedDialog && source"
              ref="full"
              :width="fullWidth"
              :height="fullHeight"
              @load="onLoadFull"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//import mixins
import windowSize from "@/mixins/windowSize.js";

export default {
  name: "ImageItem",
  components: {},
  mixins: [windowSize],
  props: {
    source: {
      type: String,
      required: true,
    },
    containerHovered: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1,
      validator: function (value) {
        if (value === 1 || value === 9 / 16) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  data() {
    return {
      loaded: false,
      hovered: false,
      appearedDialog: false,
    };
  },
  methods: {
    showDialog() {
      this.appearedDialog = true;
    },

    hideDialog() {
      this.appearedDialog = false;
    },

    enter() {
      this.animateImage(this.$refs.thumb, this.$refs.full);
    },

    leave() {
      this.animateImage(this.$refs.full, this.$refs.thumb);
    },

    onLoadFull() {
      this.loaded = true;
    },

    animateImage(startEl, destEl) {
      const start = this.getBoundForDialog(startEl);
      this.setStart(start);

      this.$nextTick(() => {
        const dest = this.getBoundForDialog(destEl);
        this.setDestination(start, {
          top: dest.top,
          left: dest.left,
          width: dest.width || this.fullWidth,
          height: dest.height || this.fullHeight,
        });
      });
    },

    getBoundForDialog(el) {
      const bound = el.getBoundingClientRect();
      const dialog = this.$refs.dialog;
      return {
        top: bound.top + dialog.scrollTop,
        left: bound.left + dialog.scrollLeft,
        width: bound.width,
        height: bound.height,
      };
    },

    setStart(start) {
      const el = this.$refs.animate;
      el.style.left = start.left + "px";
      el.style.top = start.top + "px";
      el.style.width = start.width + "px";
      el.style.height = start.height + "px";
      el.style.transitionDuration = "0s";
      el.style.transform = "";
    },

    setDestination(start, dest) {
      const el = this.$refs.animate;
      el.style.transitionDuration = "";

      const translate = `translate(${dest.left - start.left}px, ${
        dest.top - start.top
      }px)`;
      const scale = `scale(${dest.width / start.width}, ${dest.height / start.height})`;
      el.style.transform = `${translate} ${scale}`;
    },
    imageClass(className) {
      if (this.aspectRatio == 1) {
        return `${className} ${className}-1-1`;
      } else if (this.aspectRatio == 9 / 16) {
        return `${className} ${className}-16-9`;
      } else {
        return `${className} ${className}-1-1`;
      }
    },
  },
  computed: {
    fullWidth() {
      if (this.windowWidth <= this.windowHeight / this.aspectRatio) {
        return this.windowWidth * 0.9;
      } else {
        return (this.windowHeight / this.aspectRatio) * 0.9;
      }
    },
    fullHeight() {
      if (this.windowWidth <= this.windowHeight / this.aspectRatio) {
        return this.windowWidth * 0.9 * this.aspectRatio;
      } else {
        return this.windowHeight * 0.9;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@/css/variables.scss";

.zoom-button {
  opacity: 0;
  display: grid;
  grid-template-rows: 1fr;
  &--vissible {
    opacity: 0.2;
  }
  position: absolute;
  bottom: $f3;
  right: $f3;
  z-index: 2;

  cursor: pointer;

  color: $buttonBgColor;
  border: $buttonBgColor;

  &:hover {
    opacity: 1;
    color: $buttonBgColorHover;
    border: $buttonBgColorHover;
  }

  &:active {
    color: $buttonBgColorActive;
    border: $buttonBgColorActive;
  }

  &:hover {
    cursor: pointer;
  }
}

.image-dialog {
  border-radius: 4px;
  &-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-block {
    position: relative;
  }
  &-trigger {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }

  &-close {
    position: absolute;
    right: $f7;
    top: $f7;

    > svg {
      height: 1em;
    }

    display: grid;
    grid-template-rows: 1fr;

    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    color: $buttonBgColor;
    border: $buttonBgColor;

    &:hover {
      color: $buttonBgColorHover;
      border: $buttonBgColorHover;
    }

    &:active {
      color: $buttonBgColorActive;
      border: $buttonBgColorActive;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-background {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    text-align: center;
  }

  &-animate {
    display: none;
    position: absolute;
    transform-origin: left top;

    &.loading {
      display: block;
    }
  }
}

.dialog {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s ease-out;
  }

  &-enter,
  &-leave-to {
    background-color: rgba(255, 255, 255, 0);
  }

  &-enter-active .image-dialog-animate,
  &-leave-active .image-dialog-animate {
    display: block;
    transition: transform 0.3s cubic-bezier(1, 0, 0.7, 1);
  }

  &-enter-active .image-dialog-close,
  &-leave-active .image-dialog-close,
  &-enter-active .image-dialog-full,
  &-leave-active .image-dialog-full {
    visibility: hidden;
  }
}

.image {
  &__wrapper {
    position: relative;
    &-1-1 {
      padding-bottom: 100%;
    }
    &-16-9 {
      padding-bottom: math.div(9, 16) * 100%;
    }
    align-items: center;
    border-radius: 4px;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
        }
      }
    }
  }

  &__item {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  &__spinner {
    display: inline-block;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}
.loader {
  border: 5px solid darkgrey;
  border-radius: 50%;
  border-top: 5px solid black;
  position: absolute;
  &-16-9 {
    top: math.div(100% - math.div(16, 9) * 20%, 2);
    left: 40%;
    width: 20%;
    height: math.div(16, 9) * 20%;
  }
  &-1-1 {
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
  }
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
